<template>
  <div id="nav" class="flex-col w-64 h-screen absolute md:relative overflow-auto" :class="{ 'hide-menu': hideMenu, 'vertical-navigation-mode-over': resize() }">
    <!--  -->
    <div v-if="user && user.type" class="flex bg-white items-center sticky top-0 justify-between p-2">
      <!--  -->
      <router-link class="flex items-center no-active dashbord" to="/">
        <img alt="..." class="align-middle border-none img-cod" :src="image" />
      </router-link>
      <!--  -->

      <!--  -->
      <button v-if="resize()" @click="actionSidebar" class="focus:outline-none items-center flex">
        <i style="font-size: 30px;" class="material-icons text-blue-600">close</i>
      </button>
      <!--  -->
    </div>
    <!--  -->

    <!--  -->
    <div class="h-full p-2 pt-4 bg-green-600  text-white" :class="$colors.sideBar">
      <!--  -->
      <menu-link v-if="getPermission('dashboard') && (user.type !== 'Seller' || (user.type === 'Seller' && user.accountType.includes('seller')))" to="/" :text="$t('dashboard')" class="link" icon="dashboard" />
      <!--  -->

      <!--  -->
      <menu-link v-if="getPermission('warehouses') && getAccess('warehouses', 'update')" to="/warehouses" :text="$t('warehouses')" class="link" icon="business" />
      <div v-if="['warehouses', 'newWarehouse'].indexOf($route.name) > -1 && getAccess('warehouses', 'create')">
         <menu-link to="/warehouses/new" v-if="getAccess('warehouses', 'create')" :text="$t('new_warehouse')" icon="add" submenu="true" />
      </div>
      <!--  -->

      <!--  -->
      <menu-link v-if="getPermission('products') && ((user.type !== 'Seller' || (user.type === 'Seller' && user.accountType.includes('seller'))) || ['TeleConsultant', 'StockManager', 'NRP', 'CancelledOrder', 'ToRemind'].indexOf(user.type) != -1)" to="/products" :text="$t('products')" class="link" icon="local_mall" />
      <div v-if="['products', 'newProduct'].indexOf($route.name) > -1 && getAccess('products', 'create')">
        <menu-link v-if="getAccess('products', 'create')" to="/products/new" :text="$t('new_product')" icon="add" submenu="true" />
      </div>
      <!--  -->

      <!--  -->
      <menu-link v-if="getPermission('analytics') && ['Seller'].indexOf(user.type) == -1" to="/callcenter" :text="$t('analytics')" class="link" icon="trending_up" />
      <!--  -->

      

      <template v-if="getPermission('affiliates') && (user.type !== 'Seller' || (user.type === 'Seller' && user.accountType.includes('affiliate')))"> 
        
        <!--  -->
           <menu-link v-if="getPermission('affiliates')" to="#" text="Affiliates" name="affiliates"  :class="`link img ${$route.path?$route.path.replaceAll('/',''):''}`" img="https://img.icons8.com/pastel-glyph/64/FFFFFF/network-2-1--v1.png" imghover="https://img.icons8.com/pastel-glyph/64/network-2-1--v1.png" :isActive="isActive" @myFilter="myFilter('affiliates')" />
        <!--  -->

        <!--  -->
           <menu-link v-if="getPermission('dashboard') && getPermission('affiliates')" to="/affiliates/analytics?typeOrder=affiliate" :text="$t('dashboard')" name="affiliates" class="link affil" children="true"  icon="dashboard" />
           <menu-link v-if="getPermission('affiliates')" to="/affiliates/offers" text="Offers" name="affiliates" class="link affil" children="true" img="https://img.icons8.com/dotty/80/FFFFFF/tags.png" imghover="https://img.icons8.com/dotty/80/tags.png" />
           <menu-link v-if="getPermission('affiliates')" to="/affiliates/requests" :text="'Request offres'" name="affiliates" class="link affil" children="true" :img="'https://img.icons8.com/puffy/64/FFFFFF/list.png'" :imghover="'https://img.icons8.com/puffy/64/list.png'" />
        <!--  -->
        <!--  -->
      </template>

      <!--  -->
      <menu-link v-if="getPermission('orders')" to="/orders" :text="$t('orders')" class="link" icon="local_grocery_store" />
      <div v-if="['orders', 'newOrder'].indexOf($route.name) > -1 && (user.type !== 'Seller' || (user.type === 'Seller' && user.accountType.includes('seller')))">
        <menu-link to="/orders/new" v-if="getAccess('orders', 'create') && ['NRP', 'CancelledOrder', 'ToRemind', 'TeleConsultant'].indexOf(user.type) == -1" :text="$t('new_order')" icon="add" submenu="true" />
      </div>
      <!--  -->

      <!--  -->
      <menu-link v-if="user.type == 'TeleConsultant' || user.type == 'NRP' || user.type == 'CancelledOrder' || user.type == 'ToRemind'" to="/orders/confirmation" :text="$t('confirmation')" icon="add" />

      <div v-if="['orders', 'importOrders'].indexOf($route.name) > -1 && (user.type !== 'Seller' || (user.type === 'Seller' && user.accountType.includes('seller')))">
        <menu-link to="/orders/import" v-if="getAccess('orders', 'create') && ['NRP', 'CancelledOrder', 'ToRemind', 'TeleConsultant'].indexOf(user.type) == -1" :text="$t('import_orders')" icon="add" submenu="true" />
      </div>
      <!--  -->

      <!--  -->
      <menu-link v-if="getPermission('expeditions') && (user.type !== 'Seller' || (user.type === 'Seller' && user.accountType.includes('seller')))" to="/expeditions" :text="$t('expeditions')" class="link" icon="local_airport" />
      <div v-if="['expeditions', 'newExpedition'].indexOf($route.name) > -1">
        <menu-link to="/expeditions/new" v-if="getAccess('expeditions', 'create')" :text="$t('new_expedition')" icon="add" submenu="true" />
      </div>
      <!--  -->

    

      <!--  -->
      <menu-link v-if="getPermission('status') && getAccess('status', 'create')" to="/status" :text="$t('status')"  class="link" icon="sync" />
      <!--  -->

      <!--  -->
      <menu-link v-if="getPermission('users') && getAccess('users', 'create')" to="/users" :text="$t('users')" class="link" icon="account_circle" />
      <!--  -->

      <!--  -->
      <div v-if="['users', 'newUser'].indexOf($route.name) > -1">
        <menu-link to="/users/new" v-if="getAccess('users', 'create')" text="New user " icon="add" submenu="true" />
      </div>
      <!--  -->

      <!--  -->
      <div v-if="['users', 'historyUser'].indexOf($route.name) > -1 && getPermission('userHistories')">
        <menu-link to="/users/history" v-if="getAccess('userHistories', 'create')" text="History" icon="history" submenu="true" />
      </div>
      <!--  -->

      <!--  -->
      <menu-link v-if="getPermission('roles')" to="/roles" :text="$t('roles')" class="link" icon="lock" />
      <div v-if="['roles', 'newRole'].indexOf($route.name) > -1">
        <menu-link to="/roles/new" v-if="getAccess('roles', 'create')" :text="$t('new_role')" icon="add" submenu="true" />
      </div>
      <!--  -->

      <!--  -->
      <menu-link v-if="getPermission('teams')" to="/teams" :text="$t('teams')" class="link" icon="group" />
      <div v-if="['teams', 'newTeam'].indexOf($route.name) > -1">
        <menu-link to="/teams/new" v-if="getAccess('teams', 'create')" :text="$t('new_team')" icon="add" submenu="true" />
      </div>
      <!--  -->

      <!--  -->
      <menu-link v-if="getPermission('subscribers')" to="/subscribers" :text="$t('subscribers')" class="link" icon="supervisor_account" />
      <div v-if="['subscribers', 'newSubscribers'].indexOf($route.name) > -1">
        <menu-link to="/subscribers/new" v-if="getAccess('subscribers', 'create')" :text="$t('new_subscriber')" icon="add" submenu="true" />
        
      </div>
      <!--  -->

      <!--  -->
      <menu-link v-if="((getPermission('zones') && getAccess('zones', 'create')) || (getPermission('shippings') && getAccess('shippings', 'create'))) || (user.type == 'Accountant' || user.type == 'Return' || (user.type == 'Seller' && getAccess('shippings', 'read')))" to="#" :text="$t('shipping_order')" name="shippings" class="link" icon="local_shipping" :isActive="isActive" @myFilter="myFilter('shippings')" />
      <!--  -->

      <!--  -->
      <menu-link v-if="getPermission('zones') && getAccess('zones', 'create')" to="/zones" :text="$t('zones')" class="link" icon="map" name="shippings" children="true" />
      <div v-if="['zones', 'newZones'].indexOf($route.name) > -1">
        <menu-link to="/zones/new" v-if="getAccess('zones', 'create')" :text="$t('new_zone')" icon="add" class="child" submenu="true" />
      </div>
      <!--  -->

      <!--  -->
      <menu-link v-if="getPermission('shippings') && getAccess('shippings', 'create') || (user.type == 'Return' || (user.type == 'Seller' && getAccess('shippings', 'read')))" to="/shippings" :text="$t('shippings')" class="link" icon="map" name="shippings" children="true" />
      <!--  -->

      <!--  -->
      <menu-link v-if="getPermission('payments') || getPermission('charges') || getPermission('credits') || getAccess('balancesHistories', 'update')" :to="getPermission('payments')?'/payments':getPermission('charges')?'/charges':getPermission('credits')?'/credits':'/balances/requests'" :text="$t('payments')" name="paymentss"  :class="`link img ${$route.path?$route.path.replaceAll('/',''):''}`"  icon="card_giftcard"  :isActive="isActive" @myFilter="myFilter('paymentss')" />
      <menu-link v-if="getPermission('payments')" to="/payments" :text="$t('payments')" class="link" icon="payment" children="true" name="paymentss"/>
      <menu-link v-if="getPermission('charges')" to="/charges" text="Charges" class="link" icon="card_giftcard"  name="paymentss" children="true" />
      
      <menu-link v-if="getPermission('sourcings') && (user.type !== 'Seller' || (user.type === 'Seller' && user.accountType.includes('seller')))" to="/sourcings" text="Sourcings" class="link" icon="train" />
      <div v-if="['sourcings', 'newSourcing'].indexOf($route.name) > -1">
        <menu-link to="/sourcings/new" v-if="getAccess('sourcings', 'create')" text="New sourcing" icon="add" submenu="true" />
      </div>
      <!--  -->

   



      <!--  -->
      <menu-link v-if="getPermission('settings') && getAccess('settings', 'update')" to="/settings" :text="$t('settings')" class="link" icon="settings" />
      <!--  -->

      <!--  -->
      <menu-link v-if="user.accountType && user.accountType.includes('seller')" to="https://storeino.com" isLinkHref="exist" text="Create Store" class="link img" img="https://storeno.b-cdn.net/shipsen/10-2021/1633356154211.png" imghover="https://storeno.b-cdn.net/shipsen/10-2021/1633356552917.png" target="_blank" />
      <!--  -->
      <menu-link
        v-if="getPermission('senders') && getAccess('senders', 'create')"
        to="/senders"
        text="SMS sender"
        class="link img"
        img="https://img.icons8.com/sf-regular/48/ffffff/sms.png"
        imghover="https://img.icons8.com/sf-regular/48/000000/sms.png"
      />
      <!--  -->
      <menu-link to="/orders/api" v-if="user.type == 'CEO' || user.type == 'Seller'" :text="$t('API')" icon="device_hub" class="link" />
      <!--  -->
    </div>
    <!--  -->
  </div>
</template>


<script>
import image from "@/assets/logo.png";
export default {
  props: {
    user: { type: Object },
  },
  data() {
    return {
      login: false,
      token: null,
      isActive: { name: "", check: false },
      status: false,
      hideMenu: false,
      image: "https://storeno.b-cdn.net/rmexpressglob/logo.png",
    };
  },
  async mounted() {
    this.token = this.$jwtService.getToken();

    if (this.user) this.getAccess("users", "read");
    console.log('this.$store.getters.ShowSidebar;------', this.$store.getters.ShowSidebar)

  },
  watch: {
    '$store.state.open': async function (oldVal, newVal) {
      this.hideMenu = oldVal;
    },
  },
  methods: {
    ShowSidebar() {
      return this.$store.getters.ShowSidebar;
    },
    actionSidebar() {
      this.$store.dispatch('actionSidebar');
    },
    resize() {
      return this.$store.getters.resize;
    },
    myFilter: function (name) {
      this.isActive.name = name;
      this.isActive.check = !this.isActive.check;
    },
    async getStatusUser() {
      if (this.user.status == "active") {
        this.status = true;
      } else {
        this.status = false;
      }
    },
    async getUser() {
      const res = await this.$server.me("users");
      if (res.content) this.user = res.content;
      else this.user = [];
    },
    logout() {
      this.$jwtService.destroyToken();
      location.href = "/";
    },
    getAccess(modelToCheck, accessToCheck) {
      const permissions = this.user.role.permissions;
      for (let i in permissions) {
        let model = permissions[i]["model"];
        let access = permissions[i]["access"];

        if (model == modelToCheck) {
          return access[accessToCheck];
        }
      }
    },
    getPermission(name) {
      if (this.user) {
        const permissions = this.user.role.permissions;
        for (let i in permissions) {
          let model = permissions[i]["model"];
          let access = permissions[i]["access"];

          if (model == name) {
            if (
              access.create ||
              access.read ||
              access.update ||
              access.delete
            ) {

              if ((this.user.type == 'CancelledOrder' || this.user.type == 'ToRemind') && name == 'shippings') return false
              else return true;
            } else {
              return false;
            }
          }
        }
      }
    },
  },
};
</script>

<style>
.img-cod {
  display: block !important;
}

.hide-menu {
  visibility: hidden;
  margin-left: -280px;
}

.dashbord img {
  width: 125px;
}

.link:hover {
  background-color: white;
  color: #ff5b2d;
  border-radius: 6px;
}

.img-link img:last-child {
  display: none;
}

.link:hover img:last-child,
.router-link-exact-active img:last-child {
  display: block;
}

.link:hover img:first-child,
.router-link-exact-active img:first-child {
  display: none;
}

.vertical-navigation-mode-over {
  position: fixed !important;
  transform: translateZ(0);
  left: 0 !important;
  top: 0 !important;
  bottom: 0;
}

#nav {
  transition-duration: .4s;
  transition-timing-function: cubic-bezier(.25, .8, .25, 1);
  transition-property: visibility, margin-left, margin-right, transform, width, max-width, min-width;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  background-color: #ff5b2d;
  position: sticky;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  top: 0;
  width: 260px;
  min-width: 260px;
  max-width: 260px;
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  z-index: 10;
}
</style>
